<template>
  <div>
    <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
    <b-row>
      
      <b-col lg="6">
        <b-card title="Formulário de Importação">
          <b-form-group
            label="Selecione um produto:"
            label-for="i-nome"
            class="pl-1 pr-1"
          >
            <b-form-select
              :options="listSelect"
              v-model="dataImport.produto_id"
              text-field="nome"
              value-field="id"
            />
          </b-form-group>
          <b-form-group
            label="Selecione o mês de referência:"
            label-for="i-nome"
            class="pl-1 pr-1"
          >
            <b-form-select
              :disabled="dataImport.produto_id == null"
              :options="optionsMonth"
              v-model="dataImport.mes_ref"
            />
          </b-form-group>
          <b-form-group
            label="Selecione uma planilha:"
            label-for="i-nome"
            class="pl-1 pr-1"
          >
            <b-form-file
              :disabled="dataImport.produto_id == null"
              placeholder="Arquivo"
              v-model="excel"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              name="excel"
            />
          </b-form-group>
        </b-card>
        <b-card>
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                @click="importExcel"
              >
                Importar
              </b-button>
            </b-col>
            <b-col
              ><b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                block
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <div class="text-center">
          <b-img
            fluid
            width="500"
            :src="require('@/assets/images/undraw/spreadsheets.svg')"
          />
        </div>
      </b-col>
      <b-col lg="6">
        <b-card no-body>
          <b-card-header
            class="
              d-flex
              justify-content-between
              align-items-center
              pt-52
              pb-75
            "
          >
            <h4>Log da importação</h4>
            <div>
              <b-badge
                variant="light-success"
                v-if="logMensage.status == 'success'"
              >
                Importado com sucesso
              </b-badge>
              <b-badge
                variant="light-danger"
                v-if="logMensage.status == 'error'"
              >
                Erro na importação
              </b-badge>
            </div>
          </b-card-header>
          <div class="log-style overflow-auto" v-if="showLog == true">
            <div v-if="logMensage.status == 'success'" class="text-center mt-5">
              <h3>{{ logMensage.message }}</h3>
              <p class="mt-2">
                <b-img
                  fluid
                  width="300"
                  :src="require('@/assets/images/undraw/success.svg')"
                />
              </p>
            </div>
            <div v-if="logMensage.status == 'error'">
              <b-table-simple striped responsive size="sm">
                <b-thead>
                  <b-tr class="text-center">
                    <b-th>Linha</b-th>
                    <b-th>Colaborador</b-th>
                    <b-th>Data de Importação</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    :key="indextr"
                    v-for="(tr, indextr) in logMensage.data_error"
                    class="text-center"
                  >
                    <b-td>
                      {{ tr.linha_error }}
                    </b-td>
                    <b-td>
                      {{ tr.colaborador }}
                    </b-td>
                    <b-td>
                      {{ tr.created_at | dateTimeFormat }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </div>
          <div class="text-center" v-if="showLog == false">
            <h3>Realize uma importação para exibir os logs!</h3>
            <p class="mt-2">
              <b-img
                fluid
                width="400"
                :src="require('@/assets/images/undraw/log.svg')"
              />
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    </b-overlay>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    mapActions,
    mapState,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Variáveis Global
      isLoading: true,
      excel: null,

      dataImport: {
        produto_id: null,
        mes_ref: null,
        usuario_id: 1,
      },

       optionsMonth: [
        { value: null, text: 'Selecione uma mês' },
        { value: '1', text: 'Janeiro' },
        { value: '2', text: 'Fevereiro' },
        { value: '3', text: 'Março' },
        { value: '4', text: 'Abril' },
        { value: '5', text: 'Maio' },
        { value: '6', text: 'Junho' },
        { value: '7', text: 'Julho' },
        { value: '8', text: 'Agosto' },
        { value: '9', text: 'Setembro' },
        { value: '10', text: 'Outubro' },
        { value: '11', text: 'Novembro' },
        { value: '12', text: 'Dezembro' },
      ],

      logMensage: {},

      listSelect: [],

      showLog: false,

      //Paginação
      perPage: 9,
      currentPage: 1,
      totalRows: null,
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules"]),
  },
  methods: {
    /*
    / Listar e paginar
    */
    fetchModules(paginated = false) {
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: "produtos",
          paginated: paginated
        })
        .then((response) => {
          response.forEach((value, index) => {
            this.listSelect.push({
              nome: value.nome,
              id: value.id,
              rota: value.rota,
            });
          });
        })
        .catch((response) => {
          console.error(response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    importExcel() {
			this.showLog = false;
      this.isLoading = true;
      this.listSelect.forEach((value, index) => {
        if (value.id == this.dataImport.produto_id) {
          this.dataImport.rota = value.rota;
        }
      });
      this.dataImport.excel = this.excel;
      this.$store
        .dispatch("connectAPI/import_produto", {
          data: this.dataImport,
        })
        .then((response) => {
          this.showLog = true;
          if (response.data.status == "success") {
            this.logMensage.status = response.data.status;
            this.logMensage.message = response.data.message;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Sistema de Importação!",
                icon: "ThumbsUpIcon",
                text: "Operação executada com sucesso.",
                variant: "success",
              },
            });
          } else {
            this.logMensage.status = response.data.status;
            this.logMensage.message = response.data.message;
            this.logMensage.data_error = response.data.data_error;
						this.$toast({
              component: ToastificationContent,
              props: {
                title: "Sistema de Importação!",
                icon: "XIcon",
                text: "Erro ao executar a operação.",
                variant: "danger",
              },
            });
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchModules();
  },
  watch: {},
};
</script>
<style lang="scss">
.log-style {
  width: 100%;
  height: 600px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(75, 74, 74, 0.473);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 27, 27);
  border-radius: 10px;
}
.text-format {
  width: 2.3ch;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
</style>
